<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="computedDateFormatted"
          label="Date"
          persistent-hint
          :prepend-icon="icons.mdiCalendar"
          readonly
          v-bind="attrs"
          v-on="on"
          :dense="dense"
          :outlined="outlined"
          :class="customClass"
          :hint="hint"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        no-title
        @input="menu = false"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { mdiCalendar } from "@mdi/js";

export default {
  props: {
    identifier: {
      type: String,
      default: "date-picker-single-day",
    },
    componentKey: {
      type: Number,
      default: 0,
    },
    dateInit: {
      type: String,
      default: () => {
        return "yesterday"; // one of
      },
      validator(value) {
        return ["null", "yesterday", "late-yesterday", "n-days-ago"].includes(
          value
        );
      },
    },
    nDays: {
      type: String,
      default: () => {
        return "1"; // one of
      },
      validator(value) {
        let intValue = parseInt(value);
        return intValue == value && value >= 1;
      },
    },
    dense: {
      type: String,
      default: null,
    },
    outlined: {
      type: String,
      default: null,
    },
    customClass: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: "Format DD/MM/YYYY",
    },
  },
  emits: ["onSelect"],
  name: "DatePickerSingleDay",
  data: (vm) => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substring(0, 10),
    dateFormatted: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10)
    ),
    menu: false,
    icons: {
      mdiCalendar,
    },
  }),
  created() {
    let d = new Date();
    let previousDay = new Date(d.getTime());
    if (this.dateInit == "null") {
      // this.computedDateFormatted = null;
      console.log("this.dateInit null");
      this.date = null;
      this.dateFormatted = null;
      return;
    } else if (this.dateInit == "yesterday") {
      previousDay.setDate(d.getDate() - 1);
    } else if (this.dateInit == "n-days-ago") {
      previousDay.setDate(d.getDate() - parseInt(this.nDays));
    } else if (this.dateInit == "late-yesterday") {
      previousDay.setDate(d.getDate() - 2);
    }
    this.date = new Date(previousDay - previousDay.getTimezoneOffset() * 60000)
      .toISOString()
      .substring(0, 10);
    this.dateFormatted = this.date;
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
      this.$emit("onSelect", this.date, this.identifier);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>

<style scoped>
/* 
[role="menu"] {
  top: 215px !important;
  right: 0px !important; 
}*/
</style>
